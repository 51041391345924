var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Cwi9gMDJp29awy80DPtGG"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://64ae160dd0ed439f9a6114820cc91f8e@o894200.ingest.sentry.io/4505480850767872',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampler(samplingContext) {
    // disable pageload transaction
    if (samplingContext.transactionContext.op === 'pageload') {
      return 0.005;
    }

    return 0.2;
  },
  ignoreErrors: [/Cannot read property 'im_guest_enable' of undefined/i],
  enabled: process.env.NEXT_PUBLIC_ENV === 'production',

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
